import { Header } from "./Header";
import { ActiveRequests } from "./Home";
import { useEffect, useRef, useState } from "react";
import { requests, submitRequest } from "./request-db";
import { useParams } from "react-router-dom";
import classNames from 'classnames';

interface Message {
    sender?: string;
    text: any;
    typing?: boolean;
    done?: boolean;
    opts?: string[];
}

export function ChatMessage(props: { msg: Message }) {
    const { sender, text } = props.msg;
    const bg = sender?.endsWith('System') ? 'color-bg-accent' : sender != null ? 'color-bg-attention' : null;
    return <>
        <div className={classNames("Box p-2 mx-3 mt-2", bg, !sender && 'float-right')} style={{ maxWidth: '80%' }}>
            <div className="d-flex">
                <div className="flex-1">
                    {!!sender && <div className="text-bold"> {sender} </div>}
                    <div>
                        {text}
                        {props.msg.typing && <span className="AnimatedEllipsis" />}
                    </div>
                    {props.msg.opts && <div className="float-right">
                        {props.msg.opts.map((x, i) => <span key={`opt-${i}`} className="Label ml-2">{x}</span>)}
                    </div>}
                </div>
                {!!props.msg.done && <div>
                    <button className="btn btn-primary">Mark done</button>
                </div>}
            </div>
        </div>
        <div className="clearfix" />
    </>;
}

function ChatInput(props: { pushChat: (chat: Message) => void, setWaiting: (x: boolean) => void }) {
    function keyUp(evt: any) {
        if (evt.key != 'Enter') return;
        props.pushChat({ text: (evt.target as any).value });
        (evt.target as any).value = '';
        props.setWaiting(false);
    }
    return <div className="p-2 pt-3">
        <input
            className="form-control width-full"
            autoFocus
            onKeyUp={keyUp}
        />
    </div>;
}

// each null is 500ms. 'wait' means wait for user chat
const script = [
    null,
    { msg: 'Totally legit! Been there' },
    null,
    { msg: 'Can you tell me your total revenue in Germany? (ballpark, over or under 1m)' },
    'wait',
    null,
    { msg: "Ah simple then (under 1m euro)" },
    null,
    {
        msg: "Just use userbase.de -- it's postgres compatible and has the best built-in import script. Could also check out databunker if you really want to self host for some reason",
        opts: ['userbase.de', 'databunker'],
    },
    'wait',
    { msg: "Glad to help!" },
    null,
    { sender: 'System', msg: 'Looks like you may be done?', done: true },
];

export function Chat(props: {}) {
    const { id } = useParams();
    const req = requests.filter(x => x.id == id)[0];
    const [messages, setMessages] = useState<Message[]>([]);
    const [remScript, setRemScript] = useState(script);
    const [waiting, setWaiting] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    function pushChat(chat: Message) {
        setMessages(messages.concat(chat));
        setTimeout(() => {
            if (scrollRef.current) scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }, 100);
    }

    // run script, ugh react is bad at time
    useEffect(() => {
        const handle = setInterval(() => {
            if (!remScript) return;
            if (remScript[0] == 'wait') {
                if (!waiting) setRemScript(remScript.slice(1));
                return;
            }
            setWaiting(true);
            const command: any = remScript[0];
            setRemScript(remScript.slice(1));
            if (command == null) return;
            const msg = {
                sender: command.sender || (req.chats && req.chats[0].name),
                text: command.msg,
                done: command.done,
                opts: command.opts,
            };
            pushChat(msg);
        }, 500);
        return () => clearInterval(handle);
    }, [remScript, setRemScript, req, messages, setMessages]);

    // set initial system msg
    useEffect(() => {
        if (messages.length == 0) pushChat({ sender: `System`, text: req?.prompt });
    }, [id, setMessages]);

    return <div className="d-flex flex-column" style={{ height: '100vh', maxHeight: '100vh' }}>
        <Header page='Chat' />
        <div className="Layout flex-1">
            <div className="Layout-main Box my-3 mr-3 color-bg-inset d-flex flex-column">
                <div style={{ flexBasis: 0, flexGrow: 1, overflowY: 'scroll' }} ref={scrollRef}>
                    <section id="messages">
                        {messages.map((x, i) => <ChatMessage key={`msg-${i}`} msg={x} />)}
                    </section>
                </div>
                <ChatInput {...{ pushChat, setWaiting }} />
            </div>
            <div className="Layout-sidebar d-none d-sm-block pl-3">
                <ActiveRequests noClickAbove={true} />
            </div>
        </div>
    </div>;
}
