export interface ResumeEntry {
    place: string;
    tasks: string[];
}

// [term, score]
export type TagScore = [string, number];

export interface ExpertProfile {
    name: string;
    avatarUrl?: string;
    resume: ResumeEntry[];
    bio?: string;
    tagScores: TagScore[];
    bidRange: [number, number],
}

export const experts: ExpertProfile[] = [
    {
        name: 'Heilig Bauch',
        resume: [{place: 'Spotify Germany', tasks: ['Head of EU personalization']}],
        tagScores: [['userdata', 3], ['gdpr', 5]],
        bidRange: [90, 120],
    },
    {
        name: 'Data McLocality',
        resume: [{place: 'McDichael Dunn and Crusher', tasks: ['Domicile consultant for tech exports']},],
        tagScores: [['intl', 4], ['germany', 3]],
        bidRange: [150, 300],
    },
];

export type SearchRet = [number, TagScore[], ExpertProfile];

export function searchUsers(terms: string[]): SearchRet[] {
    if (!terms.length) return [];
    const results: SearchRet[] = [];
    for (const expert of experts) {
        const iTerms = expert.tagScores.filter(([x]) => terms.includes(x));
        const score = iTerms.reduce((prev, cur) => prev + cur[1], 0);
        results.push([score, iTerms, expert]);
    }
    results.sort((a, b) => b[0] - a[0]);
    return results;
}
