import classNames from 'classnames';
import type { SearchRet } from './users';
import { CheckboxIcon, SquareIcon } from '@primer/octicons-react';

export type ProfileProps = { searchRet: SearchRet };

export function ProfileCard(props: ProfileProps & { onClick: () => void, extraClasses: string, icon: any }) {
    const { searchRet } = props;
    const tags = searchRet[1].sort((a, b) => b[1] - a[1]);
    return <div
        className={classNames('Box p-2 mt-2', props.extraClasses)}
        onClick={props.onClick}
    >
        <div className="d-flex">
            <div className="flex-1">
                <div>{searchRet[2].name}</div>
                <div>
                    Consults:
                    {tags.map((x) => <span key={x[0]} className="Label ml-2">{x[0]}: {x[1]}</span>)}
                </div>
            </div>
            <div>
                {props.icon}
            </div>
        </div>
        <hr />
        <div>
            {searchRet[2].resume.slice(0, 2).map(x => <div key={`${searchRet[2].name}-${x.place}`}>
                <div>{x.place} </div>
                <div className="color-fg-muted">
                    {x.tasks[0]}
                </div>
            </div>)}
        </div>
    </div>;
}

export function BidProfile(props: ProfileProps & { checked: boolean, toggle: (name: string) => void }) {
    const { searchRet } = props;
    return <ProfileCard {...props}
        onClick={() => props.toggle(searchRet[2].name)}
        extraClasses={props.checked ? 'color-bg-success' : ''}
        icon={props.checked ? <CheckboxIcon size='medium' /> : <SquareIcon size='medium' />}
    />;
}
