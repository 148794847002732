import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { Home } from './Home';
import { ComposeStack } from './ComposeStack';
import { Chat } from './Chat';
import { Choose } from './Choose';
import { submitRequest } from './request-db';
import './index.css';
import '@primer/css/index.scss';

const router = createHashRouter([
  { path: '/', element: <Home /> },
  { path: '/compose/:id', element: <ComposeStack submitRequest={submitRequest} /> },
  { path: '/choose/:id', element: <Choose /> },
  { path: '/chat/:id', element: <Chat /> },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);
