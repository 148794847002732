// db.ts -- data (local for now)

import { createContext } from "react";

type genTags = (match: string | RegExpMatchArray) => string[];
// [string or regex matcher, list of strings or function to generate list of strings, stride length to match]
type NerRow = [string | RegExp, string[] | genTags, number];

function nerByStride(ner: NerRow[]): [number, NerRow[]][] {
    const lookup: Record<string, NerRow[]> = {};
    for (const row of ner) {
        (lookup[row[2]] ||= []).push(row);
    }
    return Object.entries(lookup)
        .map(([key, rows]) => [Number.parseInt(key), rows]);
}

const complianceRegions: Record<string, string[]> = {
    california: ['ccpa'],
    germany: ['gdpr'],
};

export const NER: NerRow[] = [
    [/tool(s)?/, ['tool choice'], 3],
    [
        /client in (\w+)/i,
        (match) => complianceRegions[match[1]]
            ? ['intl', match[1], ...complianceRegions[match[1]]]
            : ['intl'],
        3
    ],
    ['user data', ['databases', 'userdata'], 2],
    ['user management', ['databases', 'userdata'], 2],
    [/gdpr/i, ['compliance'], 1],
    ['compliant', ['compliance'], 1],
    [/(pick a \w+|rip out)/, ['tool choice'], 3],
];
const byStride = nerByStride(NER);

function* strides<T>(arr: T[], n: number): Generator<T[]> {
    for (let i = 0; i < arr.length - n + 1; ++i) {
        yield arr.slice(i, i + n);
    }
}

/** strides() wrapper which joins by delim */
function* strStrides(arr: string[], n: number, delim: string = ' '): Generator<string> {
    for (const toks of strides(arr, n)) {
        yield toks.length == 1
            ? toks[0]
            : toks.join(delim);
    }
}

/** add mkTerms to dest if match */
function applyMatch(dest: string[], match: string | RegExpMatchArray | null, mkTerms: string[] | genTags): void {
    if (!match) return;
    if (mkTerms instanceof Array) {
        dest.push(...mkTerms);
    } else {
        dest.push(...mkTerms(match));
    }
}

/** local silly version of term extraction */
export async function localNer(val: string): Promise<string[]> {
    if (!val) return [];
    const toks = val.split(/[^\w]+/);
    const terms: string[] = [];
    const sleep = new Promise(r => setTimeout(r, 500));
    for (const [nStride, ners] of byStride) {
        for (const tok of strStrides(toks, nStride)) {
            for (const [matcher, mkTerms] of ners) {
                let iTerms: string[] = [];
                applyMatch(
                    iTerms,
                    matcher instanceof RegExp ? matcher.exec(tok)
                        : matcher == tok && tok || null,
                    mkTerms
                );
                for (const term of iTerms) {
                    if (!terms.includes(term)) terms.push(term);
                }
            }
        }
    }
    await sleep;
    return terms;
}

export const SearchTermsContext = createContext<{ terms: string[] }>({ terms: [] });
