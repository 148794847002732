import { useNavigate, useParams } from "react-router-dom";
import { Header } from "./Header";
import { requests } from "./request-db";
import { ProfileCard, ProfileProps } from "./ProfileCards";
import { ThumbsupIcon } from '@primer/octicons-react';
import { SearchRet } from "./users";
import { ConfirmationDialog, useConfirm } from "@primer/react";
import { useCallback } from "react";

function ChoiceCard(props: ProfileProps & { onClick: () => void }) {
    return <ProfileCard {...props} onClick={props.onClick} extraClasses="" icon={<ThumbsupIcon />} />;
}

export function Choose(props: {}) {
    const { id } = useParams();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const req = requests.filter(x => x.id == id)[0];
    const choose = useCallback(
        async function choose(expert: SearchRet) {
            if (!await confirm({
                title: 'Select expert?',
                content: (<span>
                    Really start a chat with <b>{expert[2].name}</b>?
                    They'll be notified and will start working on your request.
                </span>),
            })) return;
            req.chats = [expert[2]];
            navigate('/');
        }, [confirm, navigate]);
    return <>
        <Header page='Choose' />
        <div className="container-sm p-3">
            <h4>Choose experts</h4>
            {req.requestedPeople.length == 1
                ? <p>{req.requestedPeople[0][2].name} answered the call -- thumbs up to get going</p>
                : <p>{req.requestedPeople.length} people answered the call -- choose below</p>
            }

            <div className="Box p-2">
                <h5>Prompt</h5>
                {req.prompt}
            </div>
            <section>
                {req.requestedPeople.map((expert, i) => <ChoiceCard
                    key={`choice-${i}`}
                    searchRet={expert}
                    onClick={() => choose(expert)}
                />)}
            </section>
        </div>
    </>;
}
