import { useCallback, useState } from "react";
import { Bid, PromptRequest } from "./Bid";
import { Composer } from "./Composer";
import { Header } from "./Header";
import { SearchTermsContext } from "./db";
import { ExpertProfile, SearchRet } from "./users";

export function ComposeStack(props: { submitRequest: (req: PromptRequest) => void }) {
    const [val, setVal] = useState('');
    const [terms, setTerms] = useState<string[]>([]);
    function submitRequest(rets: SearchRet[]) {
        props.submitRequest({
            prompt: val,
            terms,
            requestedPeople: rets,
        });
    }
    return <>
        <Header page='Compose' />
        <main className="container-md px-3">
            <div className="col-12 col-md-6 float-md-left">
                <Composer {...{ terms, setTerms, val, setVal }} />
            </div>
            <div className="col-12 col-md-6 float-md-left pl-md-3">
                <SearchTermsContext.Provider value={{ terms }}>
                    <Bid {...{ submitRequest }} />
                </SearchTermsContext.Provider>
            </div>
        </main>
    </>;
}
