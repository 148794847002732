import { Link } from "react-router-dom";
import { Header } from "./Header";
import { requests } from "./request-db";
import { PromptRequest } from "./Bid";
import { CommentIcon, ThumbsupIcon } from '@primer/octicons-react';

function ActiveRequestLink(props: { req: PromptRequest, noLabel?: boolean }) {
    const { req } = props;
    const dest = req.chats == null ? 'choose' : 'chat';
    const state = req.chats == null
        ? <><ThumbsupIcon /> Choose</>
        : <><CommentIcon /> Chat</>;
    return <div className="d-flex">
        <Link to={`/${dest}/${props.req.id}`} style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {props.req.prompt}
        </Link>
        {!props.noLabel && <span className="Label ml-2">{state}</span>}
    </div>;
}

export function ActiveRequests(props: { noClickAbove?: boolean }) {
    return <section id="requests-active" className="mt-3 Box p-3">
        <div className="h5">Active requests</div>
        {requests.length == 0
            ? <div className="blankslate">
                <h3 className="blankslate-heading">No active requests</h3>
                {!props.noClickAbove && <p>Click above to create one</p>}
            </div>
            : requests.map((x, i) => <ActiveRequestLink req={x} key={`req-${i}`} noLabel={props.noClickAbove} />)
        }
    </section>;
}

export function Home(props: {}) {
    return <>
        <Header page='' />
        <div className="container-sm p-3">
            <div>
                <Link to='/compose/new' className="btn btn-outline">New request</Link>
            </div>
            <ActiveRequests />
        </div>
    </>;
}
