import { Link } from "react-router-dom";

/** header with dummy user login */
export function Header(props: { page: string }) {
    return <div className="Header">
        <div className="Header-item">
            <Link to='/' className="Header-link f4">
                <span>Threaded</span>
            </Link>
        </div>
        <div className="Header-item Header-item--full">{props.page}</div>
        <div className="Header-item mr-0">
            {/* <img className="avatar" height="20" alt="@octocat" src="https://github.com/octocat.png" width="20"> */}
            USER
        </div>
    </div>;
}
