import { useCallback, useContext, useState } from "react";
import { SearchTermsContext } from "./db";
import { ExpertProfile, SearchRet, searchUsers } from "./users";
import { RocketIcon, CheckboxIcon, SquareIcon, SmileyIcon } from '@primer/octicons-react';
import classNames from 'classnames';
import { useNavigate } from "react-router-dom";
import { BidProfile } from "./ProfileCards";

export function BidHeader(props: { bid: number, setBid: (fn: (x: number) => number) => void, n: number, nAvail: number, children: any }) {
    // todo: bid boost
    return <div className="Box p-2">
        <div className="d-flex">
            <div className="flex-1">
                {props.n.toString()} results with bid ${props.bid.toString()}
                <div className="color-fg-muted">{props.nAvail - props.n} more available with a higher bid</div>
            </div>
            <button className="btn" onClick={() => props.setBid(x => x == 100 ? 200 : 100)}><RocketIcon /></button>
        </div>
        {props.children}
    </div >;
}

export interface PromptRequest {
    id?: string;
    prompt: string;
    terms: string[];
    requestedPeople: SearchRet[];
    acceptedPeople?: ExpertProfile[]; // 1-sided matches
    chats?: ExpertProfile[]; // 2-sided matches
}

/** top container for bid UX */
export function Bid(props: { submitRequest: (rets: SearchRet[]) => void }) {
    const { terms } = useContext(SearchTermsContext);
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const [bid, setBid] = useState(100);
    // const [results, setResults] = useState<ExpertProfile[]>([]);
    const users = searchUsers(terms);
    const shownUsers = users.filter(user => user[2].bidRange[0] <= bid);
    const [checked, setChecked] = useState<Record<string, boolean>>({});
    const toggle = useCallback(
        (name: string) =>
            setChecked(old => Object.assign({}, old, { [name]: !old[name] })),
        [setChecked]
    );
    const size = Object.values(checked).filter(x => x).length;
    const onRequest = useCallback(function onRequest() {
        setSubmitting(true);
        try {
            const names = Object.entries(checked)
                .filter(([name, checked]) => checked)
                .map(([name]) => name);
            const filtered = shownUsers.filter(row => names.includes(row[2].name));
            props.submitRequest(filtered);
            console.log('submitted request');
            navigate('/');
        } catch (err) {
            setSubmitting(false);
        }
    }, [checked, shownUsers]);
    // request btn adds to global list
    return <section id="bid">
        <div className="form-group">
            <div className="form-group-header">
                <label>Bid controls</label>
            </div>
            <BidHeader {...{ bid, setBid, n: shownUsers.length, nAvail: users.length }}>
                <div className="mt-2">
                    <button
                        className="btn btn-primary"
                        disabled={submitting || size == 0}
                        onClick={onRequest}
                    >
                        Request {size != 0 && size.toString()}
                    </button>
                </div>
            </BidHeader>
        </div>
        <div>
            {shownUsers.map((x, i) => <BidProfile
                key={`prof-${i}`}
                searchRet={x}
                checked={!!checked[x[2].name]}
                toggle={toggle}
            />)}
        </div>
    </section>;
}
