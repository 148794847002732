import { Dispatch, SetStateAction, createRef, useCallback, useEffect, useState } from "react";
import { localNer } from "./db";
import autosize from 'autosize';

const samplePrompt = "Just signed our first client in germany + we need to move our user data to a managed tool before we can onboard them.\n\nCan someone suggest the minimum compliant tool here that we can drop in quickly without breaking our system?"

export function NERChip(props: { term: string }) {
    return <span className='Label mr-2 mt-2 Label--large'>
        {props.term}
    </span>;
}

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export function Composer(props: { terms: string[], setTerms: (terms: string[]) => void, val: string, setVal: Dispatch<SetStateAction<string>> }) {
    const [loading, setLoading] = useState(0);
    const textarea = createRef<HTMLTextAreaElement>();
    useEffect(() => {
        if (textarea.current) autosize(textarea.current);
        console.log('ok autosize');
    }, [textarea.current]);
    const onChange = useCallback((val: string) => {
        props.setVal(val);
        setLoading(x => x + 1);
        localNer(val).then(x => {
            props.setTerms(x);
            setLoading(x => x - 1);
        });
    }, []);
    async function populateSamplePrompt() {
        const toks = samplePrompt.split(' ');
        let cumulative = '';
        for (const tok of toks) {
            await sleep(20 * tok.length);
            cumulative += tok + ' ';
            props.setVal(cumulative);
            localNer(cumulative).then(props.setTerms);
        }
    }
    return <section id="composer">
        <div className="form-group">
            <div className="form-group-header">
                <label htmlFor="prompt-input" onClick={populateSamplePrompt}>Prompt</label>
            </div>
            <p className="color-fg-muted">Describe your ask here. We'll pull out key bits and find someone who can help.</p>
            <div className="form-group-body">
                <textarea
                    style={{ minHeight: 'inherit' }}
                    rows={2}
                    id="prompt-input"
                    className="form-control"
                    placeholder="Here you go"
                    value={props.val}
                    ref={textarea}
                    onChange={evt => onChange(evt.target.value)}
                />
            </div>
        </div>
        <div className="my-2">
            {props.terms.map((x, i) => <NERChip key={`term-${i}`} term={x} />)}
            {loading > 0 && <span className="AnimatedEllipsis"></span>}
        </div>
    </section>;
}
